<template>
  <div class="companyList-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div :loading="loading" class="common-screen-container">
      <div class="common-input-container">
        <span>分公司名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => commonFun()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div>
    <el-button class="common-screen-btn" type="primary" @click="() => addEdit()"
      >新建分公司</el-button
    >
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="name"
        label="分公司名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="分公司状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == "1" ? "正常" : "暂停" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="分公司地址"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createBy"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
      ></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => addEdit(scope.row)"
            >编辑</el-button
          >
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="deleteCompany(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="tableRow ? '编辑分公司' : '新建分公司'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleFormCom"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公司名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              maxLength="50"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="address">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.address"
              maxLength="50"
              placeholder="公司地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司状态" prop="status">
            <el-radio v-model="ruleFormAdd.status" label="1">正常</el-radio>
            <el-radio v-model="ruleFormAdd.status" label="-1">暂停</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleFormCom');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  getCompanyList,
  addCompany,
  editCompany,
  deleteCompany,
} from "../../service/manage.js";
import { tableColumn } from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      centerDialogVisible: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "分公司设置", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        // name: "",
        // companyId: null,
        // roleId: null,
        // userDepartId: null,
      },
      tableRow: "", // 列表item
      tableData: [],
      ruleFormAdd: {
        // 新增编辑form
        name: "",
        address: "",
        status: "1",
      },
      rules: {
        name: [{ required: true, message: "请填写公司名称", trigger: "change" }],
        address: [{ required: true, message: "请填写公司地址", trigger: "change" }],
        status: [{ required: true, message: "请选择", trigger: "change" }],
      },
    };
  },
  created() {
    this.getCompanyList();
    // setTimeout(() => {
    //   this.$vuex.dispatch("getMenuInfo");
    // }, 5000);
  },

  methods: {
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getCompanyList();
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRow.id;
      let ruleFormAdd = this.ruleFormAdd;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            let res = await editCompany(ruleFormAdd, tableRowId);
            if (res.code == 200) {
              this.$message.success("修改成功");
            }
          } else {
            let res = await addCompany(ruleFormAdd);
            if (res.code == 200) {
              this.$message.success("添加成功");
            }
          }
          this.$vuex.dispatch("getMenuInfo");
          this.params.pageNum = 1;
          this.params.pageSize = 10;
          this.centerDialogVisible = false;
          this.getCompanyList();
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getCompanyList();
    },
    resetBtn() {
      // 筛选重置
      this.params = {
        pageNum: 1,
        pageSize: 10,
        // name: "",
        // companyId: null,
        // roleId: null,
        // userDepartId: null,
      };
      this.getCompanyList();
    },
    async getCompanyList() {
      this.loading = true;
      let params = { ...this.params };
      let resData = (await getCompanyList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    addEdit(row) {
      this.centerDialogVisible = true;
      if (row) {
        // 编辑获取详情
        this.tableRow = row;
        this.ruleFormAdd = {
          name: row.name,
          address: row.address,
          status: row.status + "",
        };
      } else {
        // 新增清空数据
        this.tableRow = "";
        this.ruleFormAdd = {
          name: "",
          address: "",
          status: "1",
        };
        if (this.$refs["ruleFormCom"]) {
          this.$refs["ruleFormCom"].resetFields();
        }
      }
    },
    async deleteCompany(id) {
      await deleteCompany({}, id);
      this.$vuex.dispatch("getMenuInfo");
      this.$message.error("删除成功");
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getCompanyList();
    },
  },
};
</script>
<style lang="less" scoped>
.companyList-container {
  text-align: left;
}
.dialog-input {
  width: 320px !important;
}
</style>
